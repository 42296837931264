import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import iconCite from "../../img/icon-quote.png";
// benefits videos
import videoLayers from "../../img/hc2-layers.mp4";
import videoLayersWebm from "../../img/hc2-layers.webm";
import videoAssets from "../../img/protect-assets.mp4";
import videoAlerts from "../../img/custom-alerts.mp4";
// benefits posters
import posterLightning from "../../img/hc2-lightning.jpg";
import posterAssets from "../../img/protect-asset-poster.jpg";
import posterAlerts from "../../img/custom-alerts-poster.jpg";
import posterLayers from "../../img/hc2-layers-cover.jpg";
import {Box, Button, Container, Image, T2} from "../../styles/Styles";

import hero from "../../img/hero-construction-ind.png";
import Clients from "../../components/Homepage/Clients";

const benefits = [
    {
        webm: null,
        mp4: videoAlerts,
        poster: posterAlerts,
        title: "作業員の安全性をカスタマイズしたアラートで向上",
        caption: "現場アラートをカスタマイズ",
        text: `雷から強風といった、安全上のリスクをもたらす天候<nobr>状況</nobr>が実際に起こる前に、作業員にSMSやEメール、モバイルアプリで通知。`
    },
    {
        webm: videoLayersWebm,
        mp4: videoLayers,
        poster: posterLayers,
        title: "現場特有の気象予報で<nobr>オペレーション</nobr>効率を向上",
        caption: "雨があなたのロケーションへ移動するか追跡",
        text: `特定の現場に報告する必要性と、天候<nobr>状況</nobr>において資材と人員のニーズを正確に予測できるかを定義`
    },
    {
        webm: null,
        mp4: videoAssets,
        poster: posterAssets,
        title: "MicroWeather予報で<nobr>資産を保護</nobr>",
        caption: "時間とともに関心のある天候<nobr>状況</nobr>が変化するのを確認",
        text: `天候に敏感なタスクをスケジュールするために、いつ悪天候が始まり、終わるのかを理解し、資産に影響を及ぼす天候<nobr>状況</nobr>に備える。`
    },
    {
        webm: null,
        mp4: null,
        poster: posterLightning,
        title: "雷予報",
        caption: null,
        text: `雷嵐の予想通り道を理解することで、激しさと移動型嵐の方向性により、あり得る雷の脅威に対して備えることが可能。`
    }
];

export default class ConstructionIndustry extends React.Component {
    render() {
        return (
            <Layout>
                <SEO
                    title="建設 | tomorrow.io"
                    image={hero}
                    pathname={this.props.location.pathname}
                />

                <Container>
                    <Box display="flex" flexWrap="wrap" pb={4}>
                        <Box
                            width={[1, "60%"]}
                            pt={5}
                        >

                            <Box fontSize={17} mb={3}>
                                今までに天候関連でプロジェクトの遅延や中止を経験したことがありますか？
                            </Box>
                            <T2 as="h1" mb={4}>
                                いつスタッフを送りだし、
                                <nobr>いつ仕事を切り上げるのかを知る</nobr>
                            </T2>
                            <Button
                                as="a"
                                display={["block", "inline-block"]}
                                mb={2}
                                href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                            >
                                tomorrow.ioデモ取得
                            </Button>{" "}
                            <Button
                                outline="1"
                                as="a"
                                display={["block", "inline-block"]}
                                mb={2}
                                href="https://www.tomorrow.io/api-signup/"
                            >
                                APIキー取得
                            </Button>
                        </Box>
                        <Box width={[1, "40%"]} pt={2} px={3} textAlign="center">
                            <Image
                                maxHeight="380px"
                                src={hero}
                                alt=""
                            />
                        </Box>
                    </Box>
                </Container>

                <section className="c-section py-5">
                    <div className="container">
                        <Clients/>
                    </div>
                </section>

                <section className="c-section ">
                    <div className="container">
                        <div className="col-lg-7 mx-auto text-center px-2">
                            <h2 className="h-2-sm">
                                建設業界は天候関連の遅延により
                                <nobr>年間</nobr>
                                で膨大な損失を被っています。
                            </h2>
                            <div className="fs-xs mb-4">NOAA</div>
                            <p className="fs-lg">
                            tomorrow.ioの分単位、通り毎レベルの予報のお陰で、建設会社は天候
                                <nobr>状況</nobr>
                                に沿った、賢い計画を立てるのに必要な情報を得ることができ、チームと資産を保護し、顧客満足を向上させます。
                            </p>
                        </div>
                        {benefits.map((item, i) => (
                            <div key={i} className="row justify-content-center py-4 py-lg-6">

                                <div
                                    className={`col-lg-4 pr-lg-4 ${
                                        i % 2 === 0 ? "order-lg-last" : ""
                                    }`}
                                >
                                    <h3 className="h-3" dangerouslySetInnerHTML={{__html: item.title}}/>
                                    <p className="fs-lg" dangerouslySetInnerHTML={{__html: item.text}}/>
                                </div>

                                <div className="col-lg-7">

                                    <div className="embed-responsive embed-responsive-16by9">
                                        <video
                                            className="embed-responsive-item shadow"
                                            poster={item.poster}
                                            width="800"
                                            height="480"
                                            controls={false}
                                            muted
                                            playsInline
                                            autoPlay
                                            loop
                                        >
                                            {item.webm && (
                                                <source src={item.webm} type="video/webm"/>
                                            )}
                                            {item.mp4 && <source src={item.mp4} type="video/mp4"/>}
                                            } Your browser does not support the video tag
                                        </video>
                                    </div>
                                    <div className="text-center px-4 py-2"
                                         dangerouslySetInnerHTML={{__html: item.caption}}/>

                                </div>
                            </div>
                        ))}
                        <div className="text-center pt-6">

                            <a
                                className="btn btn-lg btn-primary h-3 "
                                href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                            >
                                tomorrow.ioデモ取得
                            </a>

                        </div>
                    </div>
                </section>

                <section className="c-section pt-5 ">

                    <div className="col-lg-6 mx-auto text-center">
                        <img className="mb-3" src={iconCite} alt=""/>
                        <p
                            className="fs-lg font-italic mb-4"
                            style={{fontFamily: "Georgia, serif"}}
                        >
                            私たちの大きなチャレンジは、どの天気予報でも天気は良好と認識し、スタッフを送りだした後に、<nobr>突然</nobr>どこからともなく雷を伴った嵐が
                            <nobr>やって</nobr>
                            くるような時。今では、アラートシステムのお陰で、<br/>事前に自動で知ることができるようになりました。
                        </p>
                        <p className="mb-3">
                            ザック・クラーク氏、Director of Residential Operations、White Castle Roofing
                        </p>
                        <img
                            className="rounded-circle mb-5"
                            width={150}
                            src="https://cms2.climacell.co/wp-content/uploads/2019/04/zach-clarke.png"
                            alt=""
                        />
                        <div className="text-center">
                            <a
                                className="btn btn-lg btn-primary h-3 "
                                href="https://www.tomorrow.io/downloads/download-construction/"
                            >
                                もっと読む
                            </a>
                        </div>
                    </div>

                </section>

            </Layout>
        );
    }
}
